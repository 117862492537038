// http://ionicframework.com/docs/theming/
@import url("https://fonts.googleapis.com/css2?family=Yantramanav&display=swap");

@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "swiper/scss";
@import "swiper/scss/autoplay";
@import "swiper/scss/pagination";
@import "@ionic/angular/css/ionic-swiper";

// EXTRA GLOBAL STYLES
// Add custom Ionic Colors
@import "theme/custom-ion-colors.scss";
// Add base app styles
@import "theme/app-defaults.scss";
// Add base shell styles
@import "theme/shell-defaults.scss";

//save this everywhere
html {
--standard-border-radius: 14px;
}

.authmodal {
    --height: min;
    --background: black;
}

.authiddle {
    --height: min;
    --max-width: 390px;
    --background: black;
    border-radius: 14px;
    --border-radius: 14px;
}